<template>
  <div>
    <Modal :options="{width: '80vw',esc:false}">
      <div class="title">Solicitud</div>
      <div class="body">
        <Visualizador :solicitud_id="solicitud_id" tipo_visualizacion="modal" @solicitud="cargar_solicitud" />
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="show_modal=true">Ceder</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>

    <Ceder v-if="show_modal" :solicitud="solicitud" @close="cerrar_modal" />
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import Visualizador from '@/apps/analyticpoint/pages/Solicitud/Visualizador';
import Ceder from './Info/Ceder';

import api from '@/apps/analyticpoint/api/comite/cesiones';

export default {
  components: {
    Modal, Visualizador, Ceder
  },
  props: {
    solicitud_id: 0
  },
  data() {
    return {
      solicitud: null,
      show_modal: false
    }
  },
  methods: {
    cargar_solicitud(solicitud) {
      this.solicitud = solicitud;
    },
    cerrar_modal(close_all) {
      this.show_modal=false;
      
      if (close_all)
        this.$emit('update');
    }
  }
}
</script>