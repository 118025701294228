<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12">Cesiones</h1>
    </div>

    <div class="row my-3">
      <div class="col-sm-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Solicitudes</span>
          </div>
          <select v-model="ver_solicitudes" name="ver_solicitudes" id="ver_solicitudes" class="form-control">
            <option value="comite">Ver comité actual</option>
            <option value="todas">Todas las solicitudes</option>
          </select>
        </div>
      </div>
      <div class="col-sm-3">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text">Buscar</span>
          </div>
          <input v-model="buscar" type="text" name="buscar" id="buscar" class="form-control">
        </div>
      </div>
      <div class="col-sm-6 info text-right">
        Estatus solicitud: <span class="abierta"></span> Abierta <span class="aceptada"></span> Aceptada <span class="rechazada"></span> Rechazada <button @click="cargar_solicitudes" class="btn btn-secondary"><i class="fa-solid fa-rotate"></i> Recargar</button>
      </div>
    </div>

    <template v-if="raw_solicitudes && raw_solicitudes.last_page">
    <Paginacion :data="raw_solicitudes" @paginado="cambiar_pagina" />
    </template>

    <div v-if="solicitudes.length == 0" class="solicitudes">
      <div>No hay solicitudes por el momento</div>
    </div>
    <div v-else class="solicitudes">
      <div v-for="solicitud in solicitudes" :class="'solicitud my-2'+resolucion_solicitud(solicitud)">
        <div class="header">
          <div class="id_solicitd"><strong>Solicitud ID:</strong> {{ $auth.can('analyticpoint','usuario_cesion_admin') ? solicitud.solicitud_id : solicitud.solicitud.solicitud_id }}</div>
          <div class="nombre"><strong>Nombre:</strong> {{ $auth.can('analyticpoint','usuario_cesion_admin') ? solicitud.nombre : solicitud.solicitud.nombre }}</div>
          <div class="monto"><strong>Monto:</strong> ${{ $auth.can('analyticpoint','usuario_cesion_admin') ? $helper.moneyFormat(solicitud.monto_original) : $helper.moneyFormat(solicitud.solicitud.monto_original) }}</div>
          <div class="resolucion"><strong>Resolución comité:</strong> {{ solicitud.resolucion ? solicitud.resolucion : '--' }}</div>
          <div class="resolucion"><strong>Resolución cesión:</strong> {{ solicitud.resolucion ? solicitud.resolucion : '--' }}</div>
          <div class="control"><button class="btn btn-light" @click="abrir_modal($auth.can('analyticpoint','usuario_cesion_admin') ? solicitud.id : solicitud.solicitud_id)">Revisar</button></div>
        </div>
      </div>
    </div>

    <template v-if="raw_solicitudes && raw_solicitudes.last_page">
    <Paginacion :data="raw_solicitudes" @paginado="cambiar_pagina" />
    </template>

    <Info v-if="show_info" :solicitud_id="this.solicitud_id" @close="cerrar_modal" @update="cerrar_modal(true)" />
  </div>
</template>

<script>
  import Info from './Cesion/Info';
  import Paginacion from '@/apps/analyticpoint/components/Paginacion.vue';

  import api from '@/apps/analyticpoint/api/comite/cesiones';

  export default {
    components: {
      Info, Paginacion
    },
    data() {
      return {
        solicitudes: [],  
        solicitud_id: 0,
        show_info: false,
        filtrar_solicitudes: 'para_ceder',
        ver_solicitudes: 'comite',
        buscar: null,
        pagina_actual: 1,
        por_pagina: 10,
        raw_solicitudes: null,
        opciones: {
          page: 1,
          limit: 20,
          col_order: 'id',
          col_dir: 'desc'
        }
      }
    },
    mounted() {
      this.cargar_solicitudes();
    },
    methods: {
      async cargar_solicitudes() {
        try {
          this.raw_solicitudes = (await api.obtener_cesiones(this.ver_solicitudes, this.opciones)).data;
          this.solicitudes = this.ver_solicitudes == 'todas' ? this.raw_solicitudes.data : this.raw_solicitudes;
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      },
      estatus_solicitud(solicitud) {
        if (!solicitud.show)
          return 'cerrado';

        return 'abierto';
      },
      abrir_modal(solicitud) {
        this.solicitud_id = solicitud;
        this.show_info = true;
      },
      cerrar_modal(update) {
        if (typeof update == 'undefined')
          update = false;

        this.solicitud_id = null;
        this.show_info = false;

        if (update)
          this.cargar_solicitudes();
      },
      resolucion_solicitud(solicitud) {
        if (!solicitud.resolucion) return '';

        return solicitud.resolucion == 'aceptado' ? ' solicitud_aprobada' : ' solicitud_rechazada';
      },
      cambiar_pagina(opcion) {
        this.opciones = opcion;

        this.cargar_solicitudes();
      }
    },
    watch: {
      filtrar_solicitudes(val) {
        this.cargar_solicitudes();
      },
      ver_solicitudes() {
        this.pagina_actual = 1;
        this.cargar_solicitudes();
      },
      buscar() {
        this.opciones.search = this.buscar;
        this.cargar_solicitudes();
      }
    }
  }
</script>

<style lang="scss" scoped>
  $solicityd_abierta: #0781cd;
  $solicitud_votada: #0d8b22;
  $solicitud_rechazada: #971212;
  .info {
    span {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      display: inline-block;
    }

    span.abierta {
      background-color: $solicityd_abierta;
    }

    span.aceptada {
      background-color: $solicitud_votada;
    }

    span.rechazada {
      background-color: $solicitud_rechazada;
    }
  }
  .solicitudes {
    .solicitud {
      background-color: $solicityd_abierta;
      // width: 90%;
      margin: auto;
      color: #fff;
      padding: 20px 15px;
      border-radius: 5px;

      .header {
        display: flexbox;

        .id_solicitd,.nombre,.monto,.resolucion,.control {
          width: 15%;
          display: inline-block;
        }

        .nombre {
          width: 30%;
        }

        .control {
          width: 5%;
          text-align: center;

          i {
            transform: rotate(90deg);
            cursor: pointer;
          }
        }
      }
    }

    .solicitud_aprobada {
      background-color: $solicitud_votada;
    }

    .solicitud_rechazada {
      background-color: $solicitud_rechazada;
    }
  }

@media screen and (max-width: 576px) {
  .solicitudes {
    .solicitud {

      .header {
        .id_solicitd,.nombre,.monto,.control {
          width: 100%;
          text-align: right;
          display: block;

          strong {
            float: left;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 577px) and (max-width: 667px) {
  .solicitudes {
    .solicitud {
      .header {
        .id_solicitd,.nombre,.monto,.control {
          width: 20%;
        }

        .nombre {
          width: 45%;
        }

        .control {
          width: 15%;
        }
      }
    }
  }
}

@media screen and (min-width: 668px) and (max-width: 1180px) {
  .solicitudes {
    .solicitud {
      .header {
        .id_solicitd,.nombre,.monto,.control {
          width: 20%;
        }

        .nombre {
          width: 50%;
        }

        .control {
          width: 10%;
        }
      }
    }
  }
}

@media screen and (min-width: 668px) and (max-width: 1180px) and (orientation: portrait) {
  .solicitudes {
    .solicitud {
      .header {
        .id_solicitd,.nombre,.monto,.control {
          width: 20%;
        }

        .nombre {
          width: 45%;
        }

        .control {
          width: 10%;
        }
      }
    }
  }
}
</style>